import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "class-recordings",
      "style": {
        "position": "relative"
      }
    }}>{`Class Recordings`}<a parentName="h1" {...{
        "href": "#class-recordings",
        "aria-label": "class recordings permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Given how spread out we are, not everyone can always attend all the classes we host. On this page, you will find a growing set of recordings we have made from different mindful movements we have explored together.`}</p>
    <h2 {...{
      "id": "collaborations",
      "style": {
        "position": "relative"
      }
    }}>{`Collaborations`}<a parentName="h2" {...{
        "href": "#collaborations",
        "aria-label": "collaborations permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Movement Composition with Marlon`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/Oahm1kuJ1-Y" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Embodied Design with Reyna J. Perdomo`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/1s-eFt-TaMw" mdxType="Video" />
      </Box>
    </Column>
    <h2 {...{
      "id": "somatic-practice",
      "style": {
        "position": "relative"
      }
    }}>{`Somatic Practice`}<a parentName="h2" {...{
        "href": "#somatic-practice",
        "aria-label": "somatic practice permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Movement Rehab`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/ljysCOCiYaw" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Walking on the Milky Way with My Sneakers`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/4e8VLanwckg" mdxType="Video" />
      </Box>
    </Column>
    <h2 {...{
      "id": "intro-to-ballet",
      "style": {
        "position": "relative"
      }
    }}>{`Intro to Ballet`}<a parentName="h2" {...{
        "href": "#intro-to-ballet",
        "aria-label": "intro to ballet permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Intro to Ballet`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/6g5uvxULqvQ" mdxType="Video" />
      </Box>
    </Column>
    <h2 {...{
      "id": "trance-and-hiit-workout",
      "style": {
        "position": "relative"
      }
    }}>{`Trance and HIIT Workout`}<a parentName="h2" {...{
        "href": "#trance-and-hiit-workout",
        "aria-label": "trance and hiit workout permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Dance HIIT`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/q8dgTL9LqYY" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Trance Dance`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/QF0YQyAzC88" mdxType="Video" />
      </Box>
    </Column>
    <h2 {...{
      "id": "yoga",
      "style": {
        "position": "relative"
      }
    }}>{`Yoga`}<a parentName="h2" {...{
        "href": "#yoga",
        "aria-label": "yoga permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Vinyasa Flow`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/kzUsJGi7hro" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Yoga Flow 2`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/JIeV20EWrWY" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Yoga Flow 3`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/RG4bLb5Ykn8" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Yoga Nidra`}</strong>{` (not ours, but great)`}</p>
        <Video src="https://www.youtube-nocookie.com/embed/wvt8uERA_zE" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      